<template>
  <div class="product-selector__date">
    <v-collapse-wrapper v-on:beforeToggle="toggleCollapsed">

      <h3>{{question.phrase}}</h3>
      <tooltip
        :question="question"
        v-if="question.showTooltip()"
      ></tooltip>

      <div v-collapse-toggle ref="collapseToggle">
        <svg role="presentation">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg--arrow"></use>
        </svg>
      </div>
      <div v-collapse-content>
        <datepicker
          v-model="answer"
          @selected="notifyOfChange"
          :disabled-dates="disabledDates"
        ></datepicker>
      </div>

    </v-collapse-wrapper>
  </div>
</template>

<script>

import Datepicker from 'vuejs-datepicker';
import Tooltip from '../tooltip.vue';

export default {
  components: {
    Datepicker,
    Tooltip,
  },
  props: [
    'question',
    'initialAnswer',
  ],
  data() {
    return {
      answer: this.initialAnswer,
      collapsed: false,
    };
  },
  computed: {
    disabledDates() {
      if (this.question.disableDatesFrom) {
        return {
          to: new Date(this.question.disableDatesTo),
          from: new Date(this.question.disableDatesFrom),
        };
      }

      return { to: new Date(this.question.disableDatesTo) };
    },
  },
  methods: {
    notifyOfChange(date) {
      // For some strange reason the date is supplied with the current time added onto it.
      const dateWithoutTimeBits = date.setUTCHours(0, 0, 0, 0);
      this.$store.dispatch(
        'setQuestionAsAnswered',
        {
          question: this.question,
          selectedAnswer: dateWithoutTimeBits,
        },
      );
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.$refs.collapseToggle.classList.toggle('collapsed');
    },
  },
  watch: {
    initialAnswer() {
      // This watch method is required so reset can change the parent's data
      //  - and this value will be re-assigned
      this.answer = this.initialAnswer;
    },
  },
};

</script>
