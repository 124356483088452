<template>
  <div class="product-selector__checkboxes" v-bind:class="{ disabled: this.question.disabled }">
    <v-collapse-wrapper v-on:beforeToggle="toggleCollapsed">

      <h3>{{question.phrase}}</h3>
      <tooltip
        :question="question"
        v-if="question.showTooltip()"
      ></tooltip>

      <div v-collapse-toggle ref="collapseToggle">
        <svg role="presentation">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg--arrow"></use>
        </svg>
      </div>
      <div v-collapse-content>

        <div
          v-for="answer in question.answers.slice(0, this.maxQuantityWithoutHide())"
          :key="answer.id"
          class="field field--checkbox"
          >
          <!------------------------------------------------------->
          <!-- This is the checkbox+label start (identical code) -->
          <label
            :for="answer.id"
            v-bind:class="{ disabled: isDisabled(answer) }"
            >
            <input
              type="checkbox"
              :id="answer.id"
              :value="answer.id"
              :disabled="isDisabled(answer)"
              v-model="selectedAnswerIds"
              @change="notifyOfChange"
              >{{answer.phrase}}
              <div class="checkbox__indicator"></div>
          </label>
          <!-- This is the checkbox+label end (identical code) -->
          <!------------------------------------------------------->
        </div>

        <div v-if="this.requireShowMore()" v-collapse-content>
          <v-collapse-wrapper class="offer-show-more" v-on:beforeToggle="toggleShowingMore">
            <div v-collapse-content>

              <div
                v-for="answer in question.answers.slice(this.maxQuantityWithoutHide())"
                :key="answer.id"
                class="field field--checkbox"
                >
                <!------------------------------------------------------->
                <!-- This is the checkbox+label start (identical code) -->
                <label
                  :for="answer.id"
                  v-bind:class="{ disabled: isDisabled(answer) }"
                  >
                  <input
                    type="checkbox"
                    :id="answer.id"
                    :value="answer.id"
                    :disabled="isDisabled(answer)"
                    v-model="selectedAnswerIds"
                    @change="notifyOfChange"
                    >{{answer.phrase}}
                    <div class="checkbox__indicator"></div>
                </label>
                <!-- This is the checkbox+label end (identical code) -->
                <!------------------------------------------------------->
              </div>

            </div>
            <div v-collapse-toggle class="v-collapse-showmore" ref="showMoreToggle">
              {{ this.showingMore ? 'Show less' : 'Show more' }}
            </div>
          </v-collapse-wrapper>
        </div>

      </div>

    </v-collapse-wrapper>
  </div>
</template>

<script>

import Tooltip from '../tooltip.vue';

export default {
  components: {
    Tooltip,
  },
  props: [
    'question',
    'initialSelectedAnswerIds',
  ],
  data() {
    return {
      selectedAnswerIds: this.initialSelectedAnswerIds,
      collapsed: false,
      showingMore: false,
    };
  },
  methods: {
    notifyOfChange() {
      this.$store.dispatch(
        'setQuestionAsAnswered',
        {
          question: this.question,
          selectedAnswer: this.selectedAnswerIds,
        },
      );
    },
    isDisabled(answer) {
      return (
        (answer.disabled && !this.selectedAnswerIds.includes(answer.id))
        || (this.question.disabled)
      );
    },
    maxQuantityWithoutHide() {
      return 3;
    },
    requireShowMore() {
      return (this.question.answers.length > this.maxQuantityWithoutHide());
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.$refs.collapseToggle.classList.toggle('collapsed');
    },
    toggleShowingMore() {
      this.showingMore = !this.showingMore;
      this.$refs.showMoreToggle.classList.toggle('showing-more');
    },
  },
  watch: {
    initialSelectedAnswerIds() {
      // this watch method is required so reset can change the parent's data
      // - and this value will be re-assigned
      // console.log('initialSelectedAnswerIds changed');
      this.selectedAnswerIds = this.initialSelectedAnswerIds;
    },
  },
  computed: {
  },
  mounted() {
    // I -may- need to add something like the below to make the component be
    // reactive to changes from the parent:
    // this.selectedAnswerIds = this.initialSelectedAnswerIds
  },
};

</script>
