<template>
  <div class="product-selector__search">
    <v-collapse-wrapper v-on:beforeToggle="toggleCollapsed">

      <h3>{{question.phrase}}</h3>
      <tooltip
        :question="question"
        v-if="question.showTooltip()"
      ></tooltip>

      <div v-collapse-toggle ref="collapseToggle">
        <svg role="presentation">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg--arrow"></use>
        </svg>
      </div>
      <div v-collapse-content>

        <span class="product-selector__search--wrap">
          <input
            type="text"
            v-model="answer"
            :placeholder="this.prompt"
          >
          <svg role="presentation">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg--search"></use>
          </svg>
        </span>

      </div>

    </v-collapse-wrapper>
  </div>
</template>

<script>

import _debounce from 'lodash/debounce';
import Tooltip from '../tooltip.vue';

export default {
  components: {
    Tooltip,
  },
  props: [
    'question',
    'initialAnswer',
    'prompt',
  ],
  data() {
    return {
      answer: this.initialAnswer,
      collapsed: false,
    };
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.$refs.collapseToggle.classList.toggle('collapsed');
    },
  },
  watch: {
    answer: _debounce(function z() {
      this.$store.dispatch(
        'setQuestionAsAnswered',
        {
          question: this.question,
          selectedAnswer: this.answer,
        },
      );
    }, 500),
    initialAnswer() {
      // This watch method is required so reset can change the parent's data
      //  - and this value will be re-assigned
      this.answer = this.initialAnswer;
    },
  },
};

</script>
