<template>
  <article
    class="post--product post--card product-selector__card"
    :aria-label="'Product Selector: ' + course.name">
    <figure>
      <picture>
        <img class="lazyload" :src="course.imageUrl" />
      </picture>
    </figure>
    <div class="post__details">

      <p class="post__date">{{ datesLabel }}</p>

      <h4 class="post__title">
        <a :href="course.pageUrl + course.filteredProductIds">{{ course.name }}
        </a>
      </h4>

      <p class="post__price">{{ priceLabel }}</p>

    </div>
  </article>
</template>

<script>
import _uniq from 'lodash/uniq';
import dateMixin from './mixins/date-mixin';

export default {
  mixins: [dateMixin],
  props: [
    'course',
    'translationDatesAvailable',
  ],
  computed: {
    datesLabel() {
      if (this.course.filteredProductStartDates.length === 0) return '';

      const dateRange = _uniq(this.course.filteredProductStartDates).sort();

      let label = '';
      if (dateRange.length === 1) {
        label = this.formatDate(dateRange[0]);
      } else if (dateRange.length > 1) {
        label = `${dateRange.length} ${this.translationDatesAvailable}`;
      }

      return label;
    },
    priceLabel() {
      if (this.course.filteredProductPrices.length === 0) return '';

      const priceRange = _uniq(this.course.filteredProductPrices).sort();

      let label = `£${priceRange[0]}`;
      if (priceRange.length > 1) {
        label += ` - £${priceRange[priceRange.length - 1]}`;
      }

      // TODO: 'FULLY BOOKED' (when have the spec for this).

      return label;
    },
  },
};
</script>
