<template>
  <div class="product-selector__slider" v-bind:class="{ disabled: this.question.disabled }">
    <v-collapse-wrapper v-on:beforeToggle="toggleCollapsed">

      <h3>{{question.phrase}}</h3>
      <tooltip
        :question="question"
        v-if="question.showTooltip()"
      ></tooltip>

      <div v-collapse-toggle ref="collapseToggle">
        <svg role="presentation">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg--arrow"></use>
        </svg>
      </div>
      <div v-collapse-content>

        <vue-slider
          v-model="selectedAnswers"
          @error="error"
          @change="notifyOfChange"
          :disabled="this.question.disabled"
          :interval="parseFloat(interval)"
          :min="min"
          :max="max"
          :enable-cross="false"
          :tooltip="'none'"
        ></vue-slider>

        <div class="slider-increments">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>

        <div class="slider-input__wrap">
          <input
            type="number"
            v-model="selectedAnswers[0]"
            @input="notifyOfChange"
            class="slider-input slider-input__first"
          />
          to
          <input
            type="number"
            v-model="selectedAnswers[1]"
            @input="notifyOfChange"
            class="slider-input slider-input__second"
          />

          <span class="product-selector__error" >{{ errorMessage }}</span>
        </div>

      </div>

    </v-collapse-wrapper>
  </div>
</template>

<script>

import VueSlider from 'vue-slider-component';
import Tooltip from '../tooltip.vue';

const ERROR_TYPE = {
  VALUE: 1, // Value is illegal
  INTERVAL: 2, // `interval` cannot be divisible by `(max - min)`
  MIN: 3, // Value is less than min
  MAX: 4, // Value is greater than max
  ORDER: 5, // When `order` is false, `minRange/maxRange/enableCross/fixed` is still set
};

export default {
  components: {
    Tooltip,
    VueSlider,
  },
  props: [
    'question',
    'initialSelectedAnswers',
    'min',
    'max',
    'interval',
  ],
  data() {
    return {
      collapsed: false,
      errorMessage: '',
      selectedAnswers: this.initialSelectedAnswers,
    };
  },
  methods: {
    clearErrorMessage() {
      this.errorMessage = '';
    },
    error(type, msg) {
      switch (type) {
        case ERROR_TYPE.MIN:
          break;
        case ERROR_TYPE.MAX:
          break;
        case ERROR_TYPE.VALUE:
          break;
        default:
          break;
      }
      this.errorMessage = msg;
    },
    notifyOfChange() {
      this.clearErrorMessage();
      if (this.selectedAnswersValid()) {
        // only dispatch/emit if passes rudimentary validation
        this.$store.dispatch(
          'setQuestionAsAnswered',
          {
            question: this.question,
            selectedAnswer: this.selectedAnswers,
          },
        );
      }
    },
    selectedAnswersValid() {
      const sMin = parseInt(this.selectedAnswers[0], 10);
      const sMax = parseInt(this.selectedAnswers[1], 10);
      if (sMin
        && sMax
        && (sMin >= this.min)
        && (sMin <= this.max)
        && (sMax >= this.min)
        && (sMax <= this.max)
      ) {
        return true;
      }

      return false;
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.$refs.collapseToggle.classList.toggle('collapsed');
    },
  },
  watch: {
    initialSelectedAnswers() {
      // this watch method is required so reset can change the parent's data
      // - and this value will be re-assigned
      // console.log(`watcher firing ${this.initialSelectedAnswers}`);
      this.selectedAnswers = this.initialSelectedAnswers;
    },
  },
};

</script>
