<template>

  <v-popover
    offset="10"
    placement="right"
  >
    <span class="popover-trigger">?</span>
    <template slot="popover">
      <h3>{{ question.tooltipTitle }}</h3>
      <p>{{ question.tooltipDescription }}</p>
    </template>
  </v-popover>

</template>

<script>
export default {
  props: ['question'],
};
</script>
