import _cloneDeep from 'lodash/cloneDeep';
import _first from 'lodash/first';
import _flatten from 'lodash/flatten';
import _last from 'lodash/last';
import _orderBy from 'lodash/orderBy';
import _remove from 'lodash/remove';
import _uniq from 'lodash/uniq';

import Console from './console';
import benchmark from './utils/benchmark';

class Question {
  constructor(data) {
    this.id = data.id;
    this.type = data.type;
    this.phrase = data.phrase;
    this.sliderInterval = data.sliderInterval;
    this.tooltipTitle = data.tooltipTitle;
    this.tooltipDescription = data.tooltipDescription;
    this.answers = data.answers;
    this.position = data.position;
    this.disabled = true;

    // Now set disabled properties on answers.  We need to add this property
    // up-front - otherwise changes to it won't be watched reliably.
    // It's important (for filtering logic) that these starts disabled.
    if (this.answers) {
      this.answers.forEach((a) => {
        const answer = a;
        answer.disabled = true;
      });
    }

    // Start/End Dates:
    // Make these properties now so they will be watched reliably.
    if (['start date', 'end date'].includes(this.type)) {
      this.disableDatesTo = Question.startOfToday();
      this.disableDatesFrom = undefined;
    }

    // Slider Notes:
    // At the point of construction we havent loaded the products yet, so we can't yet set disabled
    // properties/range/values.
    // Also remember - sliders must always have some values to start with, so we're relying
    // on min and max being set later on, before rendering.
  }

  /* eslint-disable no-use-before-define */
  static all(data) {
    const questions = [];

    data.forEach((questionData) => {
      questions.push(new Question(questionData));
    });

    return questions;
  }
  /* eslint-enable no-use-before-define */

  static startOfToday() {
    const d = new Date();
    return d.setUTCHours(0, 0, 0, 0);
  }

  static withAnswersForUrl(questions) {
    const qArr = [];
    questions.forEach((question) => {
      qArr.push(question.withAnswersForUrl());
    });
    const newQs = qArr.join('&');
    const newState = `${newQs}`;

    return newState;
  }

  withAnswersForUrl() {
    const key = `q${this.id}=`;
    let value;

    switch (this.type) {
      case 'slider':
      case 'price slider':
        value = this.selectedAnswers; break;
      case 'dropdown':
        value = this.selectedAnswerId; break;
      case 'checkboxes':
        value = this.selectedAnswerIds; break;
      case 'search':
        value = this.answer; break;
      case 'start date':
      case 'end date':
        value = this.answer.toJSON(); // Stick with this standard.
        break;
      default:
        throw Error('Unhandled type');
    }

    return `${key}${value}`;
  }

  parseQueryValueAnswer(answer) {
    switch (this.type) {
      case 'slider':
      case 'price slider':
      case 'checkboxes':
        return answer.split(',').map((v) => parseInt(v, 10));
      case 'dropdown':
        return parseInt(answer, 10);
      case 'search':
      case 'start date':
      case 'end date':
        return answer;
      default:
        throw Error('Unhandled type');
    }
  }

  static questionsFilteredForProducts(allQuestions, products) {
    // Console.log('questionsFilteredForProducts');

    // This should return only the questions which have a matching answer for every single one of
    // the products supplied.
    // It should also set the disabled property for each question - so even if we override the
    // list of which questions to show - each question still knows whether it is disabled or not.

    const clone = Array.from(allQuestions);
    const remainingQuestions = _remove(
      clone,
      (question) => question.applicableForEveryProduct(products),
    );

    // Set the disabled property of each question - on all questions.
    const questionsToAskIds = remainingQuestions.map((x) => x.id);
    allQuestions.forEach((q) => {
      const question = q;
      if (questionsToAskIds.includes(question.id)) {
        question.disabled = false;
      } else {
        question.disabled = true;
      }
    });

    return remainingQuestions;
  }

  applicableForEveryProduct(products) {
    // This method could be in each specific class of question (and was for a while) if each is
    // notably different - i.e. if theres not just repetition of the same code.

    if (this.type === 'search') { return true; } // always applicable/available/relevant
    if (this.type === 'start date') { return true; } // always applicable/available/relevant
    if (this.type === 'end date') { return true; } // always applicable/available/relevant
    if (this.type === 'price slider') { return true; } // always applicable/available/relevant

    if (!products.length) { return false; }

    // Need presence of an intersection between the question's answers and the product's answerIds
    // This must be for EVERY product!
    let hasAnswerForEveryProduct = true;

    const questionAnswerIds = this.answers.map((x) => x.id);
    products.forEach((product) => {
      if (hasAnswerForEveryProduct) {
        const intersection = product.answerIds.filter((x) => questionAnswerIds.includes(x));
        if (!(intersection && intersection.length)) {
          hasAnswerForEveryProduct = false;
        }
      }
    });

    return hasAnswerForEveryProduct; // i.e. - include this in the final remainingQuestions array
  }

  intersectingAnswerIds(products) {
    // Collate the intersecting answer id's
    let intersectingAnswerIds = [];
    const questionAnswerIds = this.answers.map((x) => x.id);
    products.forEach((product) => {
      const intersection = product.answerIds.filter((x) => questionAnswerIds.includes(x));
      intersectingAnswerIds.push(intersection);
    });
    intersectingAnswerIds = _uniq(_flatten(intersectingAnswerIds));
    return intersectingAnswerIds;
  }

  /* eslint-disable-next-line class-methods-use-this */
  disableAnswersIfNotIncluded(answers, intersectingAnswerIds) {
    answers.forEach((a) => {
      const answer = a;
      if (!intersectingAnswerIds.includes(answer.id)) {
        answer.disabled = true;
      } else {
        answer.disabled = false;
      }
      // Console.log(answer.phrase, answer.disabled);
    });
  }

  disableFruitlessAnswers(products) {
    // This method could be in each specific class of question (and was for a while) if each is
    // notably different - i.e. if theres not just repetition of the same code.

    if (this.type === 'search') { return; } // nothing to do
    if (this.type === 'start date') { return; } // nothing to do
    if (this.type === 'end date') { return; } // nothing to do
    if (!products.length) { return; }

    // Console.log(`disableFruitlessAnswers for ${this.phrase}`, products);

    const intersectingAnswerIds = this.intersectingAnswerIds(products);
    // Disable answers from itself which aren't found on any product.
    // console.log(`About to set answers disabled flags set for ${this.phrase}`);
    this.disableAnswersIfNotIncluded(this.answers, intersectingAnswerIds);
  }

  findCachedSearchQueryResult() {
    // We query a lower-cased version to avoid case ambiguity issues.
    const query = this.answer.toLowerCase();

    if (!this.queryResults) { // might not be loaded/fetched yet
      return undefined;
    }

    const queryResult = this.queryResults.find((x) => x.query === query);
    Console.log('SEARCH:  cached queryResult is ', queryResult);
    return queryResult;
  }

  showTooltip() {
    return (this.tooltipTitle || this.tooltipDescription);
  }

  reset() {
    if (['slider', 'price slider'].includes(this.type)) {
      this.selectedAnswers = [this.min, this.max];
    } else if (this.type === 'dropdown') {
      this.selectedAnswerId = undefined;
    } else if (this.type === 'checkboxes') {
      this.selectedAnswerIds = [];
    } else if (['search', 'start date', 'end date'].includes(this.type)) {
      this.answer = '';
    } else {
      throw Error('Unhandled type');
    }
  }

  sayDebugMessage() {
    if (['slider', 'price slider'].includes(this.type)) {
      return `${this.phrase}:  ${this.selectedAnswers}.  Date now is: ${Date.now()}`;
    }

    if (this.type === 'dropdown') {
      let message = '';
      if (this.selectedAnswerId !== undefined) {
        const selectedAnswer = this.answers.find((a) => a.id === this.selectedAnswerId);
        if (selectedAnswer !== undefined) {
          message = `${this.phrase}:  ${selectedAnswer.phrase}  (id of ${selectedAnswer.id})`;
        }
      }
      return message;
    }

    if (this.type === 'checkboxes') {
      let message = '';
      if (this.selectedAnswerIds !== undefined) {
        this.selectedAnswerIds.forEach((selectedAnswerId) => {
          const answer = this.answers.find((x) => x.id === selectedAnswerId);
          message += `${this.phrase}:  ${answer.phrase}`;
        });
      }
      return message;
    }

    if (['search', 'start date', 'end date'].includes(this.type)) {
      return `${this.phrase}:  ${this.answer}`;
    }

    throw Error('Unhandled type');
  }

  // ------  Slider-only methods below:  ------

  setRange(products) {
    if (this.type !== 'slider') { throw Error('slider-only method.'); }

    // This set of products will be different(larger) than the remaining pool
    //  - so we don't want to be affecting the data of which answers are disabled
    //  - so we deep clone this - and work against that.  This method will only
    // be called once on load - so the deep clone shouldn't end up bloating memory.
    // Lodash was the only effective deep clone.  Array.from and Object.assign were
    // both not deep enough.
    Console.log(`Within setRange for ${this.phrase}, about to DEEP CLONE`);

    const intersectingAnswerIds = this.intersectingAnswerIds(products);
    const thisClone = _cloneDeep(this);
    this.disableAnswersIfNotIncluded(thisClone.answers, intersectingAnswerIds);

    const minMax = Question.minMaxNonDisabledAnswers(thisClone.answers);
    if (!minMax) { return; }
    [this.min, this.max] = minMax;
    Console.log(`min: ${this.min}, max: ${this.max}`);
  }

  autoSetSelectedAnswers() {
    if (this.type !== 'slider') {
      throw Error('Slider-only method.');
    }

    Console.log(`Auto-setting selected answers for ${this.phrase}`);
    const minMax = Question.minMaxNonDisabledAnswers(this.answers);
    if (!minMax) {
      Console.log(`minMax is: ${minMax} (no answer options possible)`);
      // Probably not an issue.  Probably loading and disabled as has no valid
      // options to offer.  Just [re]set to its min and max
      if (this.selectedAnswers === undefined) { this.reset(); }
      return;
    }
    this.selectedAnswers = minMax;
    Console.log(`this.selectedAnswers: ${this.selectedAnswers}`);
  }

  static minMaxNonDisabledAnswers(answers) {
    // only used by sliders..
    const filteredAnswers = answers.filter((x) => !x.disabled);
    const orderedAnswers = _orderBy(filteredAnswers, (answer) => {
      Number(answer.phrase);
    });
    if (orderedAnswers.length === 0) { return undefined; }
    const answersMin = Number(_first(orderedAnswers).phrase);
    const answersMax = Number(_last(orderedAnswers).phrase);
    Console.log('minMaxNonDisabledAnswers', answersMin, answersMax);
    return [answersMin, answersMax];
  }

  // ------  Price-Slider-only methods below:  ------

  setRangeFromPrices(products) {
    if (this.type !== 'price slider') { throw Error('Price-slider-only method.'); }

    [this.min, this.max] = Question.minMaxPrices(products);

    // benchmark(Question.minMaxPrices, 1000, products);
    Console.log(`setRangeFromPrices for prices slider, this.min is ${this.min}, this.max is ${this.max}`);
  }

  autoSetSelectedAnswersFromPrices(products) {
    if (this.type !== 'price slider') {
      throw Error('Price-Slider-only method.');
    }

    // Price slider has no answers at all ever, so instead we need to look at the sub-set of
    // products and find the min and max price from those.  If we have some.
    if (products) {
      this.selectedAnswers = Question.minMaxPrices(products);
      Console.log(
        `Auto-setting selected answers for ${this.phrase}`,
        `this.selectedAnswers: ${this.selectedAnswers}`,
      );
    }
  }

  static minMaxPrices(products) {
    // The below is verbose, but it's fast

    let min;
    let max;

    products.forEach((product) => {
      if (!min || product.price < min) { min = product.price; }
      if (!max || product.price > max) { max = product.price; }
    });

    return [min, max];
  }
}

export default Question;
