/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import _orderBy from 'lodash/orderBy';
import _filter from 'lodash/filter';

import Console from './console';

import Course from './course';
import Product from './product';
import Question from './question';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    allCourses: [],
    allProducts: [],
    allQuestions: [],
    filteredProducts: [],
    filteredCourses: [],
    questionsToAsk: [],
    questionsAnswered: [],
    showDisabledQuestions: true,
    debugMessages: [],
    searchUrl: '',
  },
  mutations: {
    loadAllCourses(state, data) {
      state.allCourses = data;
    },
    loadAllProducts(state, data) {
      state.allProducts = data;
    },
    loadAllQuestions(state, data) {
      state.allQuestions = data;
    },
    setSearchUrl(state, url) {
      state.searchUrl = url;
    },
    filterProducts(state) {
      state.filteredProducts = Product.filter(
        state.allProducts,
        state.questionsAnswered,
      );
    },
    filterCourses(state) {
      // Assigns a collection (a sub-set) of unique courses, with each's
      // filtered ProductIds populated.  Prices and Dates are also populated
      // here for efficiency - to avoid lots of product lookups for these later.
      const courses = [];

      state.filteredProducts.forEach((product) => {
        const course = this
          .state
          .allCourses
          .find((x) => x.id === product.courseId);
        if (!courses.includes(course)) {
          // Creating as wasn't found
          courses.push(course);
          course.filteredProductIds = [];
          course.filteredProductPrices = [];
          course.filteredProductStartDates = [];
        }
        course.filteredProductIds.push(product.id);
        course.filteredProductPrices.push(product.price);
        course.filteredProductStartDates.push(product.startDate);
      });

      state.filteredCourses = courses;
    },
    filterQuestionsToAsk(state) {
      // Note - the Question method below changes state - causes side affects - must
      // always be run as code is at present :-/
      state.questionsToAsk = Question.questionsFilteredForProducts(
        state.allQuestions,
        state.filteredProducts,
      );

      if (state.showDisabledQuestions) {
        state.questionsToAsk = state.allQuestions;
      }
    },
    filterAnswersOfQuestionsToAsk(state) {
      state.questionsToAsk.forEach((question) => {
        // Filter the question against a pool of products where this question
        // has not been answered.
        const questionsAnsweredExceptSelf = _filter(
          state.questionsAnswered,
          (o) => (o.id !== question.id),
        );
        const lessFilteredProducts = Product.filter(
          state.allProducts,
          questionsAnsweredExceptSelf,
        );
        question.disableFruitlessAnswers(lessFilteredProducts);
      });
    },
    endDateQuestionDisableTo(state, disableDatesTo) {
      const question = state.allQuestions.find((x) => x.type === 'end date');
      if (question) {
        Console.log(`Setting endDate question DisabledDatesTo ${disableDatesTo}`);
        question.disableDatesTo = disableDatesTo;
      }
    },
    startDateQuestionDisableFrom(state, disableDatesFrom) {
      const question = state.allQuestions.find((x) => x.type === 'start date');
      if (question) {
        Console.log(`Setting startDate question DisabledDatesTo ${disableDatesFrom}`);
        question.disableDatesFrom = disableDatesFrom;
      }
    },
    setQuestionsAnswered(state, questionsAnswered) {
      state.questionsAnswered = questionsAnswered;
    },
    resetQuestionsAnswered(state) {
      // reset the selectedAnswer on each question
      state.questionsAnswered.forEach((question) => {
        question.reset();
      });
      // reset the overall list of answers (e.g. used for URL)
      state.questionsAnswered = [];
    },
    saveSearchQueryResult(state, payload) {
      Console.log('Saving (mutating) search result...');
      const { question, query, matchingProductIds } = payload;
      if (!question.queryResults) { question.queryResults = []; }
      question.queryResults.push({ query, matchingProductIds });
    },
    updateDebugMessages(state) {
      state.debugMessages = [];
      state.questionsAnswered.forEach((question) => {
        // console.log(question.constructor.name, question.type);
        state.debugMessages.push(question.sayDebugMessage());
      });
    },
  },
  actions: {
    loadData(context, url) {
      return axios.get(url)
        .then((response) => {
          const { data } = response;

          let allCourses = Course.all(data.courses);
          allCourses = _orderBy(allCourses, ['position'], ['asc']); // ordering is actually unnecessary - API feed is ordered
          context.commit('loadAllCourses', allCourses);

          const allProducts = Product.all(data.courses);
          context.commit('loadAllProducts', allProducts);

          let allQuestions = Question.all(data.questions);
          allQuestions = _orderBy(allQuestions, ['position'], ['asc']); // ordering is actually unnecessary - API feed is ordered
          context.commit('loadAllQuestions', allQuestions);
        });
    },
    setSearchUrl(context, url) {
      context.commit('setSearchUrl', url);
    },
    setQuestionAsAnswered(context, payload) { // new
      const { question, selectedAnswer } = payload;
      Console.log('store#setQuestionAsAnswered', question.id, selectedAnswer);

      if (['slider', 'price slider'].includes(question.type)) {
        Console.log(`Setting ${question.type} question selectedAnswers for ${question.phrase} to be ${selectedAnswer}`);
        question.selectedAnswers = selectedAnswer;
      } else if (question.type === 'dropdown') {
        question.selectedAnswerId = selectedAnswer;
      } else if (question.type === 'checkboxes') {
        question.selectedAnswerIds = selectedAnswer;
      } else if (question.type === 'search') {
        question.answer = selectedAnswer;
      } else if (['start date', 'end date'].includes(question.type)) {
        question.answer = new Date(selectedAnswer);
      } else {
        throw Error('Unhandled question type');
      }

      // Interplay between start/end dates for which dates to disable:
      if (question.type === 'start date') {
        context.commit('endDateQuestionDisableTo', question.answer);
      }
      if (question.type === 'end date') {
        context.commit('startDateQuestionDisableFrom', question.answer);
      }

      // Add/update this question to questionsAnswered
      // We add in (and preserve) the order they were answered in, so we can
      // identify which question was last answered
      const newQuestionsAnswered = [];
      context.state.questionsAnswered.forEach((q) => {
        if (q.id !== question.id) { // add all but the question in question
          newQuestionsAnswered.push(q);
        }
      });
      // Clunky way of checking if integer or an array with items in:
      if (selectedAnswer.toString(16).length > 0) {
        newQuestionsAnswered.push(question);
      }
      Console.log('newQuestionsAnswered will be', newQuestionsAnswered);

      context.commit('setQuestionsAnswered', newQuestionsAnswered);

      if ((question.type === 'search') && (!question.findCachedSearchQueryResult())) {
        context.dispatch('fetchSearchQueryResult', question)
          .then(() => {
            context.dispatch('filterAndUpdate', question);
          });
      } else {
        context.dispatch('filterAndUpdate', question);
      }
    },
    fetchSearchQueryResult(context, question) {
      // We query a lower-cased version to avoid case ambiguity issues.
      const query = question.answer.toLowerCase();

      // Call Rails/Postgres for a superior search result
      const url = `${context.state.searchUrl}?query=${query}`;
      Console.log('SEARCH:  Preparing to query for ', query);
      return axios.get(url)
        .then((response) => {
          Console.log(`SEARCH:  Search query for ${query} has a response:`, response);
          // Store results against this question object
          // Must wait for the promise to be resolved before subsequent actions (filter etc)
          context.commit('saveSearchQueryResult', {
            question,
            query,
            matchingProductIds: response.data.matchingProductIds,
          });
        });
    },
    resetQuestionsAnswered(context) {
      context.commit('resetQuestionsAnswered');
    },
    filter(context) {
      context.commit('filterProducts');
      context.commit('filterCourses');
      context.commit('filterQuestionsToAsk');
      context.commit('filterAnswersOfQuestionsToAsk');
    },
    filterAndUpdate(context, question) {
      context.dispatch('filter');
      context.dispatch('autoSetSelectedAnswersOfSliders', { exceptQuestions: [question] });
      context.dispatch('updateDebugMessages');
    },
    initialiseRangesOfSliders(context) {
      if (context.state.allQuestions.length === 0) {
        Console.log('WARNING:  No questions to ask!  Am you calling me too early?');
      }
      Console.log('Set initial range of sliders');
      context.state.allQuestions.forEach((question) => {
        if (question.type === 'slider') {
          question.setRange(context.state.allProducts);
        }
        if (question.type === 'price slider') {
          question.setRangeFromPrices(context.state.allProducts);
        }
      });
    },
    autoSetSelectedAnswersOfSliders(context, payload) {
      const { exceptQuestions } = payload;
      Console.log('Auto-set selected answers of sliders');
      context.state.allQuestions.forEach((question) => {
        if (['slider', 'price slider'].includes(question.type)) {
          Console.log(exceptQuestions);
          if (!(exceptQuestions && exceptQuestions.includes(question))) {
            // So we can skip updating the answers of the question they've just answered, or skip
            // answers just loaded from the URL which shouldnt be instantly overridden

            if (question.type === 'slider') {
              question.autoSetSelectedAnswers();
            } else if (question.type === 'price slider') {
              question.autoSetSelectedAnswersFromPrices(context.state.filteredProducts);
            }
            // we've now set the selectedAnswers using the slider's own method
            // - and we don't need to call setQuestionAsAnswered
            // - as that would explicitly make this seem as if it's just been
            // answered - and you would no longer be able to keep adding
            // answers to the question you may have been answering.
          }
        }
      });
    },
    updateDebugMessages(context) {
      context.commit('updateDebugMessages');
    },
  },
  getters: {
  },
});

export default store;
