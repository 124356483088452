<template>
  <div class="product-selector__dropdown" v-bind:class="{ disabled: this.question.disabled }">
    <v-collapse-wrapper v-on:beforeToggle="toggleCollapsed">

      <h3>{{question.phrase}}</h3>
      <tooltip
        :question="question"
        v-if="question.showTooltip()"
      ></tooltip>

      <div v-collapse-toggle ref="collapseToggle">
        <svg role="presentation">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg--arrow"></use>
        </svg>
      </div>
      <div v-collapse-content>

        <select
          v-model="selectedAnswerId"
          @change="notifyOfChange"
          >
          <option value="">Please select...</option>
          <option
            v-for="answer in question.answers"
            :key="answer.id"
            :value="answer.id"
            :disabled="isDisabled(answer)"
            >{{answer.phrase}}
          </option>
        </select>

      </div>

    </v-collapse-wrapper>
  </div>
</template>

<script>

import Tooltip from '../tooltip.vue';

export default {
  components: {
    Tooltip,
  },
  props: [
    'question',
    'initialSelectedAnswerId',
  ],
  data() {
    return {
      collapsed: false,
      selectedAnswerId: this.initialSelectedAnswerId,
    };
  },
  methods: {
    isDisabled(answer) {
      return (
        (answer.disabled && (this.selectedAnswerId !== answer.id))
        || (this.question.disabled)
      );
    },
    notifyOfChange() {
      this.$store.dispatch(
        'setQuestionAsAnswered',
        {
          question: this.question,
          selectedAnswer: this.selectedAnswerId,
        },
      );
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.$refs.collapseToggle.classList.toggle('collapsed');
    },
  },
  watch: {
    initialSelectedAnswerId() {
      // this watch method is required so reset can change the parent's data
      // - and this value will be re-assigned
      this.selectedAnswerId = this.initialSelectedAnswerId;
    },
  },
};

</script>
