<template>
<div class="product-selector">
  <div class="product-selector__sidebar">
    <div class="product-selector__filters" v-if="loadOk">
      <div class="product-selector__clear">
        <h2>{{ translations.filter }}</h2>
        <a
          class="btn btn--rounded btn--brand"
          :href="this.pageUrl"
          @click.prevent="reset"
        >
          {{ translations.clearAll }}
        </a>
      </div>

      <form>
        <div v-if="questionsToAsk">
          <div v-for="question in questionsToAsk">

            <search
              :question="question"
              :initial-answer="question.answer"
              :prompt="translations.search"
              v-if="question.type == 'search'"
            ></search>
            <checkboxes
              :question="question"
              :initial-selected-answer-ids="(question.selectedAnswerIds !== undefined) ? question.selectedAnswerIds : []"
              v-if="question.type == 'checkboxes'"
            ></checkboxes>
            <dropdown
              :question="question"
              :initial-selected-answer-id="question.selectedAnswerId"
              v-if="question.type == 'dropdown'"
            ></dropdown>
            <slider
              :question="question"
              :initial-selected-answers="question.selectedAnswers"
              :min="question.min"
              :max="question.max"
              :interval="question.sliderInterval"
              v-if="question.type == 'slider'"
            ></slider>
            <priceSlider
              :question="question"
              :initial-selected-answers="question.selectedAnswers"
              :min="question.min"
              :max="question.max"
              :interval="question.sliderInterval"
              v-if="question.type == 'price slider'"
            ></priceSlider>
            <date
              :question="question"
              :initial-answer="question.answer"
              v-if="((question.type === 'start date') || (question.type === 'end date'))"
            ></date>

          </div>
        </div>
      </form>
    </div>
    <div class="product-selector__cta" v-if="loadOk">
      <h2>Are you looking for something more bespoke?</h2>
      <p>Do you require continuing education for your in-house team,
        or inspiration for new products?
        Or are you a group of food enthusiasts
        looking to explore artisan food production skills?</p>
      <a href="mailto:info@schoolofartisanfood" class="btn btn--rounded btn--light" data-gtm-btn-bespoke-contact-mobile>CONTACT US</a>
    </div>
  </div>

  <div class="product-selector__results" v-if="loadOk">
    <div class="wrap">
      <course-card
        v-for="course in filteredCourses"
        :course="course"
        :key="course.key()"
        :translation-dates-available="translations.datesAvailable"
      ></course-card>
      <div class="product-selector__results--none" v-if="filteredCourses.length == 0"><h3>{{ translations.nothingToShow }}</h3></div>

      <div style="background-color: pink;" v-if="showDebugInfo">
        <h3>Matching Products</h3>
        <product-card
          v-for="product in filteredProducts"
          :product="product"
          :key="`product_filtered_${product.id}`"
        ></product-card>
        <p v-if="filteredProducts.length == 0">Nothing to show</p>
        <br />
        <br />

        <h3>Responses:</h3>
        <p v-if="debugMessages" v-for="message in debugMessages">{{message}}</p>

        <h3>All Products:</h3>
        <product-card
          v-for="product in allProducts"
          :product="product"
          :key="`product_all_${product.id}`"
        ></product-card>
      </div>
    </div>

  </div>

  <div class="product-selector__loading" v-if="loading">
    <p>
      <span>
        {{ translations.loading }}
      </span>
      <svg role="presentation">
        <use xlink:href="#svg--loader"></use>
      </svg>
    </p>
  </div>

  <div class="product-selector__error" v-if="!loading && !loadOk">
    <p>{{ translations.unavailable }}</p>
  </div>

  <div class="product-selector__cta" v-if="loadOk">
    <h2>Are you looking for something more bespoke?</h2>
    <p>Do you require continuing education for your in-house team,
        or inspiration for new products?
        Or are you a group of food enthusiasts
        looking to explore artisan food production skills?</p>
    <a href="mailto:info@schoolofartisanfood" class="btn btn--rounded btn--light" data-gtm-btn-bespoke-contact-desktop>CONTACT US</a>
  </div>

</div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import VTooltip from 'v-tooltip';
import VueCollapse from 'vue2-collapse';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Console from './console';
import Question from './question';

import ProductCard from './product-card.vue';
import CourseCard from './course-card.vue';

import Checkboxes from './questions/checkboxes.vue';
import Dropdown from './questions/dropdown.vue';
import Search from './questions/search.vue';
import Slider from './questions/slider.vue';
import PriceSlider from './questions/price-slider.vue';
import Date from './questions/date.vue';

Vue.use(VTooltip);
Vue.use(VueCollapse);

export default {
  components: {
    Checkboxes,
    Date,
    Dropdown,
    Slider,
    PriceSlider,
    Search,
    ProductCard,
    CourseCard,
  },
  data() {
    return {
      rootElementId: 'psApp',
      feedUrl: '',
      pageUrl: '',
      searchUrl: '',
      translations: {},
      loading: true,
      loadOk: false,
      showDebugInfo: false,
    };
  },
  computed: {
    ...mapState([
      'allProducts',
      'allCourses',
      'allQuestions',
      'filteredProducts',
      'filteredCourses',
      'questionsToAsk',
      'questionsAnswered',
      'showDisabledQuestions',
      'debugMessages',
    ]),
  },
  created() {
    const rootElement = document.getElementById(this.rootElementId);
    if (rootElement === null) { throw Error('Root DOM Element not found'); }

    this.fetchUrls(rootElement);
    this.fetchTranslations(rootElement);

    this.$store.dispatch('loadData', this.feedUrl)
      .then(() => {
        this.loadOk = true;

        this.pullQuestionsAnsweredFromUrl();

        this.$store.dispatch('filter');
        this.$store.dispatch('initialiseRangesOfSliders');
        this.$store.dispatch('autoSetSelectedAnswersOfSliders', { exceptQuestions: this.questionsAnswered });
        this.$store.dispatch('updateDebugMessages');

        this.loading = false;
        Console.log('created() successfully completed');
      });
  },
  methods: {
    fetchUrls(rootElement) {
      this.feedUrl = (rootElement.attributes['data-feed-url'] || '').value;
      this.pageUrl = (rootElement.attributes['data-page-url'] || '').value;
      this.searchUrl = (rootElement.attributes['data-search-url'] || '').value;

      if (this.feedUrl === undefined) { throw Error('Data feed URL not found'); }
      if (this.pageUrl === undefined) { throw Error('Current page URL not found'); }
      if (this.searchUrl === undefined) { throw Error('Search query URL not found'); }

      this.$store.dispatch('setSearchUrl', this.searchUrl);
    },
    fetchTranslations(rootElement) {
      const translationLoading = (rootElement.attributes['data-translation-loading'] || '').value;
      const translationUnavailable = (rootElement.attributes['data-translation-unavailable'] || '').value;
      const translationSearch = (rootElement.attributes['data-translation-search'] || '').value;
      const translationFilter = (rootElement.attributes['data-translation-filter'] || '').value;
      const translationClearAll = (rootElement.attributes['data-translation-clear-all'] || '').value;
      const translationNothingToShow = (rootElement.attributes['data-translation-nothing-to-show'] || '').value;
      const translationDatesAvailable = (rootElement.attributes['data-translation-dates-available'] || '').value;

      if ((translationLoading === undefined)
        || (translationUnavailable === undefined)
        || (translationSearch === undefined)
        || (translationFilter === undefined)
        || (translationClearAll === undefined)
        || (translationNothingToShow === undefined)
        || (translationDatesAvailable === undefined)
      ) {
        throw Error('Missing translation(s)');
      }

      this.translations = {
        loading: translationLoading,
        unavailable: translationUnavailable,
        search: translationSearch,
        filter: translationFilter,
        clearAll: translationClearAll,
        nothingToShow: translationNothingToShow,
        datesAvailable: translationDatesAvailable,
      };
    },
    reset() {
      Console.log('Reset please');
      this.$store.dispatch('resetQuestionsAnswered');
      this.$store.dispatch('filter');
      this.$store.dispatch('autoSetSelectedAnswersOfSliders', { exceptQuestions: [] });
      this.$store.dispatch('updateDebugMessages');
    },
    pushQuestionsAnsweredToUrl() {
      // go through our questionsAnswered and push them concisely to the URL
      const newQueries = Question.withAnswersForUrl(this.questionsAnswered);
      Console.log('newQueries is: ', newQueries);

      if (this.$route.query.answered === newQueries) { return; } // avoid redundant navigation

      this.$router.push({
        query: {
          answered: newQueries,
        },
      });
    },
    pullQuestionsAnsweredFromUrl() {
      // go through our querystring and convert the values into questionsAnswered
      const queries = JSON.parse(JSON.stringify(this.$route.query));
      if (queries && queries.answered) {
        const questions = queries.answered.split('&');
        if (questions) {
          questions.forEach((q) => {
            const keyValue = q.split('=');
            if (keyValue.length === 2) {
              const questionId = parseInt(keyValue[0].replace('q', ''), 10);
              const answer = keyValue[1];
              if (questionId && answer) {
                const question = this.findQuestionById(questionId);
                const selectedAnswer = question.parseQueryValueAnswer(answer);
                if (question && selectedAnswer) {
                  Console.log('About to setQuestionAsAnswered', questionId, selectedAnswer);
                  this.$store.dispatch(
                    'setQuestionAsAnswered',
                    { question, selectedAnswer },
                  );
                }
              }
            }
          });
        }
      }
    },
    findQuestionById(id) {
      // try and load the question
      const question = this.allQuestions.find((x) => x.id === id);
      if (question === undefined) {
        Console.log(`WARNING:  Question ${id} not found, this shouldn't happen.`);
      }
      return question;
    },
    clearUrl() {
      this.$router.push({
        query: {},
      });
    },
  },
  watch: {
    questionsAnswered(val) {
      if (val.length > 0) {
        this.pushQuestionsAnsweredToUrl();
      } else {
        this.clearUrl();
      }
    },
  },
};
</script>
