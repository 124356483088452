<template>
  <div class="product-card" id="product">
    <p>
      <span>{{ product.id }} {{ product.name }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: ['product'],
};
</script>
