class Course {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.pageUrl = data.pageUrl;
    this.imageUrl = data.imageUrl;
    this.position = data.position;
    // this.productIds = data.products.map(x => x.id); // Flat for fast lookups, not presently used
    // Add calculated (~virtual) property.  Up-front so will be watched reliably.
    this.filteredProductIds = [];
    this.filteredProductPrices = [];
    this.filteredProductStartDates = [];
  }

  static all(data) {
    const courses = [];

    data.forEach((courseData) => {
      courses.push(new Course(courseData));
    });

    return courses;
  }

  key() {
    return `courses_filtered_${this.id}`;
  }
}

export default Course;
