import _remove from 'lodash/remove';
import Console from './console';

class Product {
  constructor(data) {
    this.id = data.id;
    this.courseId = data.courseId;
    this.name = data.name;
    this.startDate = new Date(data.startDate);
    this.endDate = new Date(data.endDate);
    this.price = parseFloat(data.price);
    this.answerIds = data.answerIds;
  }

  static all(coursesData) {
    const products = [];

    coursesData.forEach((courseData) => {
      const data = courseData.products;

      data.forEach((p) => {
        const productData = p;
        productData.courseId = courseData.id;
        products.push(new Product(productData));
      });
    });

    return products;
  }

  static hasIntersectionWithAnswerIds(product, answerIds) {
    // intersection from https://medium.com/@alvaro.saburido/set-theory-for-arrays-in-es6-eb2f20a61848
    const intersection = product.answerIds.filter((x) => answerIds.includes(x));
    return (intersection && intersection.length);
  }

  static filter(allProducts, answeredQuestions) {
    let filteredProducts = allProducts;

    answeredQuestions.forEach((question) => {
      Console.log(`-->  question has phrase ${question.phrase} and type ${question.type}`);
      if (question.type === 'checkboxes') {
        if ((question.selectedAnswerIds !== undefined) && (question.selectedAnswerIds.length > 0)) {
          // we have some answers - we should filter by this question

          const clone = Array.from(filteredProducts);
          filteredProducts = _remove(
            clone,
            (p) => Product.hasIntersectionWithAnswerIds(p, question.selectedAnswerIds),
          );
        }
      }

      if (question.type === 'dropdown') {
        if (question.selectedAnswerId !== undefined) {
          const selectedAnswer = question.answers.find((a) => a.id === question.selectedAnswerId);
          if (selectedAnswer !== undefined) {
            const clone = Array.from(filteredProducts);
            filteredProducts = _remove(
              clone,
              (p) => p.answerIds.includes(selectedAnswer.id),
            );
          }
        }
      }

      if (question.type === 'slider') {
        if (question.selectedAnswers !== undefined) {
          const selectedAnswerObjects = question.answers.filter((a) => (
            (Number(a.phrase) >= question.selectedAnswers[0])
            && (Number(a.phrase) <= question.selectedAnswers[1])
          ));
          const selectedAnswerIds = selectedAnswerObjects.map((x) => x.id);

          const clone = Array.from(filteredProducts);
          filteredProducts = _remove(
            clone,
            (p) => Product.hasIntersectionWithAnswerIds(p, selectedAnswerIds),
          );
        }
      }

      if (question.type === 'price slider') {
        if (question.selectedAnswers !== undefined) {
          const clone = Array.from(filteredProducts);
          filteredProducts = _remove(
            clone,
            (product) => (
              (product.price >= question.selectedAnswers[0])
              && (product.price <= question.selectedAnswers[1])
            ),
          );
        }
      }

      if (question.type === 'search') {
        if ((question.answer !== undefined) && (question.answer !== '')) {
          if (question.queryResults) { // might not be loaded/fetched yet
            const query = question.answer.toLowerCase();
            const queryResult = question.queryResults.find((x) => x.query === query);
            // Console.log('queryResult retrieved is:',
            //             queryResult.query, queryResult.matchingProductIds);
            if (queryResult) {
              const clone = Array.from(filteredProducts);
              filteredProducts = _remove(
                clone,
                (p) => queryResult.matchingProductIds.includes(p.id),
              );
            }
          }
        }
      }

      if (question.type === 'start date') {
        if ((question.answer !== undefined) && (question.answer !== '')) {
          const timelessDate = question.answer;

          const clone = Array.from(filteredProducts);
          filteredProducts = _remove(
            clone,
            (p) => timelessDate <= p.startDate,
          );
        }
      }

      if (question.type === 'end date') {
        if ((question.answer !== undefined) && (question.answer !== '')) {
          const timelessDate = question.answer;

          const clone = Array.from(filteredProducts);
          filteredProducts = _remove(
            clone,
            (p) => timelessDate >= p.endDate,
          );
        }
      }
    });

    return filteredProducts;
  }
}

export default Product;
