import Vue from 'vue';
import VueRouter from 'vue-router';
import App from '../../javascripts/application/product-selector/product-selector.vue';
import store from '../../javascripts/application/product-selector/store';

Vue.use(VueRouter);

document.addEventListener('DOMContentLoaded', () => {
  const appElement = document.getElementById('psApp');
  if (!appElement) { return; }

  const router = new VueRouter({
    mode: 'history',
    routes: [],
  });

  // eslint-disable-next-line no-unused-vars
  const app = new Vue({
    el: '#psApp',
    store,
    router,
    components: { App },
    render: (h) => h(App),
  });
});
